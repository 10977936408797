@use "/home/runner/work/coursepage/coursepage/_mantine" as mantine;
// Footer.module.scss
@use "@/styles/variables" as *;

.footerContainer {
  .heading {
    text-align: left;
    font-family: $headline-font;
  }

  .footerArea {
    background-color: #f9f9f9;
    position: relative;
  }

  .helpIconContainer {
    position: fixed;
    bottom: 15px;
    left: 15px;
    z-index: 99;

    @media (max-width: mantine.$mantine-breakpoint-xs) {
      left: 10px;
      bottom: 10px;
    }
  }
  .helpButton {
    background: white;
    border: none;
    border-radius: 50%;
    padding: 0;
    cursor: pointer;
    color: $black-color;
    height: 40px;
    width: 40px;
    aspect-ratio: 1;
    box-shadow: 0 0 2px 0 rgba(175, 178, 206, 0.56), 0 1px 4px 0 rgba(4, 4, 52, 0.1);

    &:hover {
      color: $main-color;
      background: white;
      box-shadow: 0 1px 4px 0 rgba(183, 187, 219, 0.14), 0 0 1px 0 rgba(175, 178, 206, 0.9),
        0 8px 12px 0 rgba(14, 14, 45, 0.08), 0 14px 20px 0 rgba(4, 4, 52, 0.02);
    }
    @media (max-width: mantine.$mantine-breakpoint-xs) {
      height: 30px;
      width: 30px;
    }
  }

  .popoverContent {
    padding: 5px;
    font-size: var(--mantine-font-size-sm);
  }

  .linkList {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      margin-bottom: 6px;
      a {
        text-decoration: none;
        color: #333;
        font-weight: 600;
        transition: color 0.3s;
        &:hover {
          color: $main-color;
        }
      }
    }
  }

  .logo {
    text-align: center;
    margin-bottom: 0px;
  }

  .footerBottomArea {
    text-align: center;
    font-size: 12px;
  }

  .socialLinksList {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
    li {
      margin: 0 5px;
      a {
        display: inline-block;
      }
    }
  }
}
