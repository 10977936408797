@use "/home/runner/work/coursepage/coursepage/_mantine" as mantine;
@use "@/styles/variables" as *;

.searchFormContainer {
  .searchBarButton {
    background-color: rgba(177, 99, 241, 0.13);
    border-radius: 0.5rem;
    border-color: rgba(177, 99, 241, 0.13);
    &:hover {
      cursor: pointer;
    }

    &::placeholder {
      color: $optional-color;
      opacity: 1;
      font-weight: 400;
    }
  }
}
