@use "sass:meta" as ---hqbwtn4iqcw;@use "./fonts.scss";

$body-font: "Inter", sans-serif;
$subheadline-font: "Inter", sans-serif;
$headline-font: "Inter", sans-serif;
$section-font: "Inter", sans-serif;

$main-color: #b163f1;
$main-color2: #f69220;
$optional-color: #3f4a54;
$optional-color2: #f8f9f8;
$white-color: #ffffff;
$black-color: #162238;
$transition: 0.5s;
$background-color: #e5d8cf;
$chip-success: #27ab5f;
$chip-primary: #4caaf7;
$chip-warning: #f08e39;
$chip-error: #eb5252;
$active-color: $main-color;
$link-color: #333;
$link-hover-background: #f1f1f1;

;@include ---hqbwtn4iqcw.load-css("sass-embedded-legacy-load-done:130");