@use "/home/runner/work/coursepage/coursepage/_mantine" as mantine;
@use "@/styles/variables" as *;

.navbarContainer {
  background-color: #fff;
  padding: 12px;
  width: 100%;

  .avatarBtn {
    border: none;
    border-radius: 50%;
    transition: border 0.3s ease;
    background-color: transparent;
    &:hover {
      box-shadow: 0 0 0 2px #d3d3d3;
      cursor: pointer;
    }
  }

  @media (min-width: mantine.$mantine-breakpoint-sm) {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 5px;
  }
}

.statusText {
  color: #f2994a !important;
}
